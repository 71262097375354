import { useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetClient from '../../apis/truChiro/v1/clients/getClient';
import useGetSession from '../../apis/truChiro/v1/sessions/getSession';
import { Box, Tab, CircularProgress, Typography, Tabs } from '@mui/material';
import SessionGraphs from './SessionGraphs';

export default function Dashboard() {
  const { clientId, sessionId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] =
    useOutletContext();
  const [loading, setLoading] = useState(true);
  const [client, clientError, refreshClient] = useGetClient(auth, clientId);
  const [session, sessionError, refreshSession] = useGetSession(auth, clientId, sessionId);
  const [activeTab, setActiveTab] = useState('brainScore');

  if (clientError) {
    setSnackbar('error', clientError.error);
  }

  useEffect(() => {
    if (session) {
      setLoading(false);
    }
    if (sessionError) {
      setLoading(false);
      setSnackbar(
        'error',
        'An error occurred while attempting to retrieve the session details'
      );
    }
  }, [session, sessionError]);

  const createdDate = session && session.createdDate ? new Date(session.createdDate) : null;
  const displayDate = createdDate
    ? createdDate.toLocaleDateString(user?.locale, { timeZone: user.zoneinfo })
    : '';
  const displayTime = createdDate
    ? createdDate.toLocaleTimeString(user?.locale, { timeZone: user.zoneinfo })
    : '';

  const handleTabChange = (ev, newTabValue) => {
    setActiveTab(newTabValue);
  };

  const tabStyle = {
    borderColor: 'black',
    borderStyle: 'solid',
    color: 'black',
  };

  return (
    <Box width="100%">
      <Typography variant="h3" component="h3">
        {`Client: ${(client && client.firstName) || ''} ${(client && client.lastName) || ''}`}
      </Typography>
      <Typography variant="h5" component="h5" style={{ marginTop: '0.5rem' }}>
        {`Session: ${(displayDate && displayDate) || ''} ${
          (displayTime && displayTime) || '11/15/2022 2:34 PM'
        }`}
      </Typography>
      {!session && loading && <CircularProgress color="primary" />}
      <Box display="flex" width="100%" alignItems="center" justifyContent="center" mt={2}>
        <Tabs
          value={activeTab}
          style={{ width: '100%' }}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
        >
          <Tab
            label="BrainScore EEG"
            value="brainScore"
            style={{
              ...tabStyle,
              borderWidth: '1px',
              backgroundColor: activeTab === 'brainScore' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
          <Tab
            label="HRV"
            value="hrv"
            style={{
              ...tabStyle,
              borderWidth: '1px 1px 1px 0',
              backgroundColor: activeTab === 'hrv' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
          <Tab
            label="HR"
            value="hr"
            style={{
              ...tabStyle,
              borderWidth: '1px 1px 1px 0',
              backgroundColor: activeTab === 'hr' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
          <Tab
            label="RR"
            value="rr"
            style={{
              ...tabStyle,
              borderWidth: '1px 1px 1px 0',
              backgroundColor: activeTab === 'rr' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
          <Tab
            label="Hand Temp"
            value="handTemp"
            style={{
              ...tabStyle,
              borderWidth: '1px 1px 1px 0',
              backgroundColor: activeTab === 'handTemp' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
          <Tab
            label="Skin"
            value="skin"
            style={{
              ...tabStyle,
              borderWidth: '1px 0',
              backgroundColor: activeTab === 'skin' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
          <Tab
            label="sEMG"
            value="sEMG"
            style={{
              ...tabStyle,
              borderWidth: '1px',
              backgroundColor: activeTab === 'sEMG' ? '#eeeeee' : 'white',
            }}
            sx={{ minWidth: "fit-content", flex: 1 }}
          />
        </Tabs>
      </Box>
      
      { session?.metrics && <SessionGraphs activeTab={activeTab} session={session.metrics} loading={loading} /> }
    </Box>
  );
}
