import React, { useCallback, useMemo } from 'react';
import { scaleLinear, line, lineRadial } from 'd3';

/** Ratio of arc to size of container. */
const baseRatio = 0.8;

/** Resolution of arc. */
const length = 40;

const gaugeOrigin = {
  x: 140,
  y: 140,
};

const getTickRef = (tickCount, minVal, maxVal, minAngle, maxAngle) => {
  if (tickCount % 2 === 0)
    console.warn('Ticks should be odd numbered to ensure a tick in in the middle of the gauge.');

  const idxToRef = scaleLinear()
    .domain([0, tickCount - 1])
    .range([0, 1]);

  const idxToVal = scaleLinear()
    .domain([0, tickCount - 1])
    .range([minVal, maxVal]);

  const refToDeg = scaleLinear().domain([0, 1]).range([minAngle, maxAngle]);

  return [...Array(tickCount)].map((v, i) => ({
    index: i,
    val: idxToVal(i),
    deg: refToDeg(idxToRef(i)),
  }));
};

const tickLineLong = line()
  .x(0)
  .y((d, i) => i)({ length: 35 });

const tickLineShort = line()
  .x(0)
  .y((d, i) => i)({ length: 12 });

const GaugeArc = ({
  inset = 0,
  min = 0,
  max = 1,
  maxAngle,
  minAngle,
  stroke,
  center = { x: 125, y: 135 },
  strokeWidth,
  showText = false,
}) => {
  /** Calculate max angle of arc from degrees to radians */
  const maxRatio = maxAngle / 180;
  const arcMax = Math.PI * maxRatio;

  /** Calculate min angle of arc from degrees to radians */
  const minRatio = minAngle / 180;
  const arcMin = Math.PI * minRatio;

  /** Linear map from [0,1] to [-135,135] degrees in radians */
  const refToRads = scaleLinear().domain([0, 1]).range([arcMin, arcMax]);

  /** Render scale to map [0,length - 1] to [minAndle, maxAngle] for rendering */
  const arcScale = scaleLinear()
    .domain([0, length - 1])
    .range([refToRads(min), refToRads(max)]);

  /** Actual path function to generate path. */
  const arc = lineRadial()
    .angle((d, i) => arcScale(i))
    .radius((250 * baseRatio) / 2 - inset);

  const arcPath = arc({ length });

  return (
    <path
      className="guage-arc"
      id={showText ? 'textArc' : null}
      d={arcPath}
      transform={`translate(${center.x},${center.y})`}
      fill="transparent"
      stroke={stroke}
      strokeWidth={strokeWidth}
      style={{
        transition: 'all 0.25s 0.25s',
      }}
    />
  );
};

const Label = ({
  center,
  tickCount,
  min,
  max,
  maxAngle,
  minAngle,
  offsetText,
  length = 300 / 2 - 30,
}) => {
  const ticks = getTickRef(tickCount, min, max, minAngle, maxAngle);

  return (
    <>
      {ticks.map(({ val, deg, index }) => (
        <g
          className="gauge-label"
          style={{ userSelect: 'none', textAlign: 'center' }}
          key={val}
          transform={`translate(${center.x},${center.y}) rotate(${deg})`}
        >
          <path
            d={index === 0 || index === ticks.length - 1 ? tickLineLong : tickLineShort}
            stroke="#344c69"
            strokeWidth="1"
            transform={`translate(0,${7.5 - length})`}
            style={{ transition: 'all .25s .25s' }}
          />
          <text
            transform={`translate(${offsetText},${-length})`}
            style={{ transition: 'all .25s .25s', fontSize: '70%' }}
          >
            {Number(val)}%
          </text>
        </g>
      ))}
    </>
  );
};

const Pointer = ({
  width = 12.5,
  head = (0.8 * 250) / 2 - 5,
  tail = 5,
  value = -5,
  center = { x: 125, y: 135 },
}) => {
  const pointerLine = line()([
    [width / 2, 0],
    [0, -head],
    [-(width / 2), 0],
    [0, tail],
    [width / 2, 0],
  ]);
  const valueScale = scaleLinear().domain([0, 1]).range([0, 180]);
  const pointerValue = valueScale(value);

  return (
    <path
      className="gauge-pointer"
      d={pointerLine}
      transform={`translate(${center.x}, ${center.y}) rotate(-90)`}
      fill={'#333'}
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0"
        to={`${pointerValue}`}
        dur="1.5s"
        additive="sum"
        fill="freeze"
      />
    </path>
  );
};

const Gauge = ({
  value,
  min,
  max,
  maxAngle,
  minAngle,
  tickCount,
  arcSegments,
  labelProps = {
    offsetText: -7.5,
  },
}) => {
  const valueScale = useCallback(scaleLinear().domain([min, max]).range([0, 1]), [min, max]);
  /** Value scaled to [0,1] */
  const valueRef = useMemo(() => valueScale(value), [value, valueScale]);

  return (
    <svg
      className="gauge"
      width="100%"
      height={425}
      viewBox={'10 -25 260 200'}
      style={{
        transition: 'all 0.25s 0.25s',
        marginTop: '1rem',
      }}
    >
      <GaugeArc
        center={gaugeOrigin}
        maxAngle={maxAngle}
        minAngle={minAngle}
        stroke="black"
        strokeWidth={4}
      />
      <Label
        center={gaugeOrigin}
        tickCount={tickCount}
        min={min}
        max={max}
        maxAngle={maxAngle}
        minAngle={minAngle}
        {...labelProps}
      />
      {arcSegments.map(({ min, max, color }, idx) => (
        <g key={`arcsegment-${idx}`}>
          <GaugeArc
            key={`gauge-arcsegment-${idx}`}
            inset={12}
            min={min}
            max={max}
            stroke={color}
            center={gaugeOrigin}
            maxAngle={maxAngle}
            minAngle={minAngle}
            strokeWidth={20}
          />
        </g>
      ))}
      <Pointer value={valueRef} center={gaugeOrigin} />
    </svg>
  );
};

export default Gauge;
