import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import TimeZoneSelect from '../../components/common/timeZoneSelect';
import LocaleSelect from '../../components/common/localeSelect';

export default function Settings() {
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user, setUser] =
    useOutletContext();
  const [locale, setLocale] = useState((user && user.locale) || 'en-US');
  const [timeZone, setTimeZone] = useState((user && user.zoneinfo) || '');
  const [processing, setProcessing] = useState(false);

  function handleResetSettings() {
    setLocale((user && user.locale) || 'en-US');
    setTimeZone((user && user.zoneinfo) || '');
  }

  async function handleUpdateSettings() {
    setProcessing(true);
    try {
      const newUser = await auth.updateUserInfo({
        locale: locale,
        zoneinfo: timeZone,
      });
      if (newUser.email) {
        setSnackbar('success', 'Your settings have been successfully updated.');
        setUser(newUser);
      } else {
        setSnackbar('error', 'An error occurred while attempting to update your settings.');
      }
    } catch (err) {
      setSnackbar('error', 'An error occurred while attempting to update your settings.');
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Box>
      <Typography sx={{ pb: 3 }} variant="h3" component="h3">
        Settings
      </Typography>
      <Grid container spacing={4}>
        <Grid xs>
          <LocaleSelect locale={locale} setLocale={setLocale} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid xs>
          <TimeZoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid xs>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
              disabled={processing || null}
              variant="outlined"
              color="error"
              onClick={handleResetSettings}
            >
              Cancel
            </Button>
            <Button
              disabled={processing || null}
              sx={{ ml: 1 }}
              variant="contained"
              onClick={handleUpdateSettings}
            >
              Update
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
