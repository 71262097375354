import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ResetPasswordIcon from '@mui/icons-material/LockReset';
import DisableIcon from '@mui/icons-material/DesktopAccessDisabled';
import EnableIcon from '@mui/icons-material/DesktopWindows';
import EnabledIcon from '@mui/icons-material/CheckCircle';
import DisabledIcon from '@mui/icons-material/Cancel';

export default function userCard({
  user,
  processing,
  handleEdit,
  handleResetPassword,
  handleDisable,
  handleEnable,
  handleDelete,
  locale,
  timeZone,
  sx,
}) {
  function handleEditClick() {
    handleEdit(user);
  }

  function handleResetPasswordClick() {
    handleResetPassword(user.therapistId);
  }

  function handleDisableClick() {
    handleDisable(user.therapistId);
  }

  function handleEnableClick() {
    handleEnable(user.therapistId);
  }

  function handleDeleteClick() {
    handleDelete(user.therapistId);
  }

  const createdDate = new Date(user.createdDate);
  const displayDate = createdDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = createdDate.toLocaleTimeString(locale, { timeZone: timeZone });

  return (
    <Card sx={sx}>
      <CardContent>
        <Typography variant="h6" color="primary">
          {`${user.firstName} ${user.lastName}`}
        </Typography>
        <Typography variant="subtitle1">
          <Link href={`mailto:${user.email}`}>{user.email}</Link>
        </Typography>
        <Typography variant="subtitle1">{`Created: ${displayDate} ${displayTime}`}</Typography>
        <Typography variant="subtitle1">{`Status: ${user.status}`}</Typography>
        <Typography variant="subtitle1">
          Enabled:
          {user.enabled ? (
            <EnabledIcon color="primary" fontSize="small" />
          ) : (
            <DisabledIcon color="error" fontSize="small" />
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="Edit">
          <IconButton onClick={handleEditClick} disabled={processing || null}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reset Password">
          <IconButton onClick={handleResetPasswordClick} disabled={processing || null}>
            <ResetPasswordIcon />
          </IconButton>
        </Tooltip>
        {user.enabled ? (
          <Tooltip title="Disable">
            <IconButton onClick={handleDisableClick} disabled={processing || null}>
              <DisableIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Enable">
            <IconButton onClick={handleEnableClick} disabled={processing || null}>
              <EnableIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteClick} disabled={processing || null}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
