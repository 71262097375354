import { useState } from 'react';
import validator from '../../common/validations.mjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function verifyMfa({ code, setCode, handleMfaVerify }) {
  const [codeError, setCodeError] = useState(false);

  function validate(event) {
    event.preventDefault();
    if (!validator().isValidatorCode(code)) {
      setCodeError(true);
    } else {
      setCodeError(false);
      handleMfaVerify();
    }
  }

  return (
    <Box component="form" noValidate onSubmit={validate}>
      <div>
        <TextField
          id="verification-code"
          value={code}
          onChange={(event) => {
            setCode(event.target.value);
          }}
          error={codeError}
          helperText={codeError && 'This verification code is not the right format.'}
          fullWidth
          margin="normal"
          label="Verification Code"
          autoFocus
        />
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button sx={{ ml: 1 }} variant="contained" type="submit">
          Verify Authenticator Code
        </Button>
      </Box>
    </Box>
  );
}
