import { useRef, useEffect, useState } from 'react';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  Cell,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import { round } from 'lodash';
import Gauge from './GaugeGraph';
import {
  NICounter,
  arcSegments,
  renderEventGraphs,
  featureNames,
  barGraphColorMapping,
  CustomLegend,
  CustomTooltip,
} from '../../../components/organGraph/common';
import LungGraph from '../../../components/organGraph/Lungs';
import { idealRRData } from '../../../IdealData';
import LoadSpinner from '../../../components/LoadSpinner';

const RRBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={25}
        barWidth={45}
        margin={{
          top: 5,
          right: 100,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="title" tick={false} />
        <YAxis dx={-7} allowDecimals={false} style={{ textAnchor: 'middle' }} domain={[0, 20]}>
          <Label
            value="Breaths / min."
            position={'insideLeft'}
            style={{
              fill: 'rgba(0, 0, 0, 0.87)',
              textAnchor: 'middle',
            }}
            angle={-90}
          />
        </YAxis>
        <Bar dataKey={'value'} label="Label" barSize={40}>
          {featureNames.map((featureName, index) => (
            <Cell key={`cell-${index}`} fill={barGraphColorMapping[featureName]} />
          ))}
          <LabelList
            dataKey={'value'}
            position="top"
            formatter={(val) => round(val, 2)}
            style={{ fontSize: '14px' }}
          />
        </Bar>
        <ReferenceLine y={12} stroke="grey" strokeDasharray="3 3" ifOverflow="visible">
          <Label
            value="Max. Ideal (12)"
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>
        <ReferenceLine y={6} stroke="grey" strokeDasharray="3 3">
          <Label
            value="Min. Ideal (6)"
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>

        <Legend content={<CustomLegend />} />
        {/* <Tooltip content={<CustomTooltip type="RR" />} cursor={{ fill: 'rgb(128,128,128, 0.2)' }} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const RespiratoryGraph = ({ session, loading }) => {
  let tempIndex = session?.computed_data.respiration_resilence_index * 100;
  const measurementValue = tempIndex.toFixed(2);
  const eventGraphRef = useRef();
  const maxRange = 0.9;
  const minRange = 0.7;
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (!eventGraphRef.current) return;
    renderEventGraphs(measurementValue, eventGraphRef);

    const temp = [
      {
        title: 'Eyes Open BL',
        value: session?.raw_data.respiration.eyes_open_baseline,
      },
      {
        title: 'Eyes Closed BL',
        value: session?.raw_data.respiration.eyes_closed_baseline,
      },
      {
        title: 'Math Test',
        value: session?.raw_data.respiration.math_task,
      },
      {
        title: 'Recovery 1',
        value: session?.raw_data.respiration.recovery_1,
      },
      {
        title: 'Sounds',
        value: session?.raw_data.respiration.distracting_sounds_task,
      },
      {
        title: 'Recovery 2',
        value: session?.raw_data.respiration.recovery_2,
      },
      {
        title: 'HRV Task',
        value: session?.raw_data.respiration.hrv_task,
      },
      {
        title: 'Recovery 3',
        value: session?.raw_data.respiration.recovery_3,
      },
    ];
    setValue(temp);
  }, []);

  return (
    <>
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Typography variant="h5" style={{ fontWeight: 800 }}>
                Respiratory Resilience Index
              </Typography>
              <Box marginTop="0.25rem">
                <NICounter niValue={measurementValue} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Gauge
                min={0}
                max={100}
                value={measurementValue}
                maxAngle={90}
                minAngle={-90}
                tickCount={6}
                arcSegments={arcSegments}
                minRangeValue={minRange}
                maxRangeValue={maxRange}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <LungGraph eventGraphRef={eventGraphRef} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                IDEAL Respiration Rate (6 - 12 breaths/minute)
              </Typography>
              <RRBarChart data={idealRRData} />
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                YOUR Respiration Rate - {session?.computed_data.respiration_average.toFixed(2)}{' '}
                breaths/minute
              </Typography>
              <RRBarChart data={value} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default RespiratoryGraph;
