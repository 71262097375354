import { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Stack,
  IconButton,
  Typography,
  Unstable_Grid2 as Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { debounce } from 'lodash';

import AddIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import ClientCard from '../../components/clients/card';
import ClientEdit from '../../components/clients/edit';
import request, {
  createClient,
  updateClient,
  deleteClient,
} from '../../apis/truChiro/v1/requests.mjs';
import useGetClients from '../../apis/truChiro/v1/clients/getClients';

export default function Clients() {
  const [setSnackbar, loginState, setLoginState, auth] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [clientEditDialogOpen, setClientEditDialogOpen] = useState(false);
  const [clientCreateDialogOpen, setClientCreateDialogOpen] = useState(false);
  const [client, setClient] = useState({});
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({});
  const [clients, error, refreshClients] = useGetClients(auth, params);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  function handleClientCreate() {
    setClient({});
    setClientCreateDialogOpen(true);
  }

  function handleClientEdit(client) {
    setClient(client);
    setClientEditDialogOpen(true);
  }

  function handleDialogClose() {
    setClientCreateDialogOpen(false);
    setClientEditDialogOpen(false);
  }

  async function handleCreateClient(client) {
    setProcessing(true);
    try {
      await request(createClient(auth, client));
      handleDialogClose();
      refreshClients();
      setSnackbar('success', 'The client was created successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the client.');
    }
    setProcessing(false);
  }

  async function handleUpdateClient(client) {
    setProcessing(true);
    try {
      await request(updateClient(auth, client));
      handleDialogClose();
      refreshClients();
      setSnackbar('success', 'The client information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the client information.');
    }
    setProcessing(false);
  }

  async function handleDeleteClient(clientId) {
    setProcessing(true);
    try {
      await request(deleteClient(auth, clientId));
      refreshClients();
      setSnackbar('success', 'The client was deleted successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to delete the client.');
    }
    setProcessing(false);
  }

  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      setLoading(true);
      try {
        setParams({
          searchTerm: searchValue,
        });
        refreshClients();
      } catch (err) {
        console.log(err);
        setSnackbar('error', 'An error occurred while attempting to retrieve the clients.');
      }
      setLoading(false);
    }, 1000),
    []
  );

  useEffect(() => {
    if (error) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', error.error);
    }
  }, [error]);

  const handlePageChange = (event, value) => {
    setParams({
      ...params,
      ...{ startFrom: value - 1 },
    });
    setPage(value);
  };

  const totalPages =
    clients && clients.totalClients
      ? Math.floor((clients.totalClients - 1) / clients.limit) + 1
      : 1;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
          Clients
        </Typography>
        <IconButton size="large" color="primary" onClick={handleClientCreate}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          id="filled-search"
          label="Search Clients"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            debouncedSearch(event.target.value);
          }}
        />
        <IconButton size="large" color="primary">
          <SearchIcon />
        </IconButton>
      </Stack>

      <Grid container gap={2} spacing={2} sx={{ mt: 2 }}>
        {clients &&
          clients.clients &&
          clients.clients.map((client) => {
            return (
              <Grid key={client.clientId}>
                <ClientCard
                  client={client}
                  handleEdit={handleClientEdit}
                  handleDelete={handleDeleteClient}
                />
              </Grid>
            );
          })}
        {!clients && loading ? (
          <Grid>
            <CircularProgress color="primary" />
          </Grid>
        ) : null}
      </Grid>
      {totalPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={clientEditDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Client Information</DialogTitle>
        <DialogContent>
          <ClientEdit
            client={client}
            processing={processing}
            handleUpdate={handleUpdateClient}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={clientCreateDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>New Client</DialogTitle>
        <DialogContent>
          <ClientEdit
            client={client}
            processing={processing}
            handleUpdate={handleCreateClient}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
