import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import LocaleSelect from '../common/localeSelect';
import TimeZoneSelect from '../common/timeZoneSelect';
import validator from '../../common/validations.mjs';

export default function editUser({ user, processing, handleUpdate, handleCancel }) {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [locale, setLocale] = useState(user.locale);
  const [timeZone, setTimeZone] = useState(user.timeZone);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  function validate() {
    let valid = true;
    if (!validator().isName(firstName)) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }
    if (!validator().isName(lastName)) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }
    if (valid) {
      handleUpdate({
        therapistId: user.therapistId,
        firstName: firstName,
        lastName: lastName,
        locale: locale,
        timeZone: timeZone,
      });
    }
  }

  function resetForm() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setLocale(user.locale);
    setTimeZone(user.timeZone);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={firstNameError}>
          <InputLabel htmlFor="outlined-first-name">First Name</InputLabel>
          <OutlinedInput
            id="outlined-first-name"
            type="text"
            value={firstName}
            label="First Name"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <FormHelperText>{firstNameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={lastNameError}>
          <InputLabel htmlFor="outlined-last-name">Last Name</InputLabel>
          <OutlinedInput
            id="outlined-last-name"
            type="text"
            value={lastName}
            label="Last Name"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
          <FormHelperText>{lastNameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <LocaleSelect locale={locale} setLocale={setLocale} />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TimeZoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={validate}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
