import RespiratoryGraph from './Graphs/RRGraph';
import HRVGraph from './Graphs/HRVGraph';
import HRGraph from './Graphs/HRGraph';
import SkinGraph from './Graphs/SkinGraph';
import BrainScoreGraph from './Graphs/BrainScoreGraph';
import MuscleGraph from './Graphs/MuscleGraph';
import HandTempGraph from './Graphs/HandTempGraph';

const SessionGraphs = ({ activeTab, session, loading }) => {
  return (
    <>
      {activeTab === 'rr' ? (
        <RespiratoryGraph session={session?.respiration} loading={loading} />
      ) : activeTab === 'hr' ? (
        <HRGraph session={session?.hr} loading={loading} />
      ) : activeTab === 'skin' ? (
        <SkinGraph session={session?.skin_conductance} loading={loading} />
      ) : activeTab === 'brainScore' ? (
        <BrainScoreGraph session={session?.eeg} loading={loading} />
      ) : activeTab === 'sEMG' ? (
        <MuscleGraph session={session?.sEMG} loading={loading} />
      ) : activeTab === 'handTemp' ? (
        <HandTempGraph session={session?.temperature} loading={loading} />
      ) : (
        <HRVGraph session={session?.hrv} loading={loading} />
      )}
    </>
  );
};

export default SessionGraphs;
