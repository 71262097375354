import { useState } from 'react';
import validator from '../../common/validations.mjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';

export default function changePassword({
  password,
  setPassword,
  newPassword,
  setNewPassword,
  handlePasswordChange,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  function clearPasswords() {
    setPassword('');
    setPasswordError(false);
    setNewPassword('');
    setNewPasswordError(false);
  }

  function validate(event) {
    event.preventDefault();
    let valid = true;
    if (!validator().isPassword(password)) {
      valid = false;
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (!validator().isPassword(newPassword)) {
      valid = false;
      setNewPasswordError(true);
    } else {
      setNewPasswordError(false);
    }
    if (valid) {
      handlePasswordChange();
    }
  }

  return (
    <Box component="form" noValidate onSubmit={validate}>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={passwordError}>
          <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            value={password}
            label="Current Password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoFocus
          />
          <FormHelperText>
            {passwordError && 'This password does not meet the complexity requirements.'}
          </FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={newPasswordError}>
          <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-new-password"
            type={showNewPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            value={newPassword}
            label="New Password"
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />
          <FormHelperText>
            {newPasswordError && 'This password does not meet the complexity requirements.'}
          </FormHelperText>
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button variant="outlined" color="error" onClick={clearPasswords}>
          Cancel
        </Button>
        <Button sx={{ ml: 1 }} variant="contained" type="submit">
          Set New Password
        </Button>
      </Box>
    </Box>
  );
}
