import { Route } from 'react-router-dom';
import App from '../App';
import ErrorPage from '../error-page';
import userRoutes from './user';
import clientRoutes from './clients';
import sessionRoutes from './sessions';
import settingsRoutes from './settings';

export default function routes() {
  return (
    <>
      <Route exact path="/" element={<App />}>
        {userRoutes()}
        {clientRoutes()}
        {sessionRoutes()}
        {settingsRoutes()}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </>
  );
}
