import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import LoginIcon from '@mui/icons-material/LoginOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';

export default function Header({ toggleMenu, isLoggedIn, logOut, user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function closeMenu() {
    setAnchorEl(null);
  }
  function handleLogOut() {
    closeMenu();
    logOut();
  }

  return (
    <>
      <Box component="header">
        <AppBar position="static" color="primary">
          <Toolbar pl={0}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={toggleMenu}
              sx={{ display: { xs: 'block', sm: 'none' }}} xs={'auto'}
            >
              <MenuIcon />
            </IconButton>
            <Stack width="60%" direction="row" alignItems="center">
              <Box
                component="img"
                height="100%"
                width="18%"
                src="/images/bbhs_logo.png"
                minWidth={211}
                sx={{ display: { xs: 'none', sm: 'block' }}}
                mr={2}
              />
              <Typography align="left" variant="h5" component="div">
                {'NeuroInfiniti'}
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              {user && user.full_name}
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="current user's account"
                aria-haspopup="true"
                onClick={openMenu}
              >
                <Avatar alt={user && user.full_name} src={user && user.picture} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isLoggedIn ? (
          <div>
            <MenuItem onClick={closeMenu} component={RouterLink} to="/profile">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={closeMenu} component={RouterLink} to="/settings">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              Settings
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </div>
        ) : (
          <MenuItem component={RouterLink} to="/login">
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            Login
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
