import { useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetClient from '../../apis/truChiro/v1/clients/getClient';
import useGetSessions from '../../apis/truChiro/v1/sessions/getSessions';
import { createSession, uploadSession } from '../../apis/truChiro/v1/sessions/createSession';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import SessionCard from '../../components/sessions/card';
import FileDialog from '../../components/common/fileDialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TextField from '@mui/material/TextField';

export default function Dashboard() {
  const { clientId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] =
    useOutletContext();
  const [loading, setLoading] = useState(true);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [sessionFile, setSessionFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [client, clientError, refreshClient] = useGetClient(auth, clientId);
  const [params, setParams] = useState({});
  const [sessions, sessionsError, refreshSessions] = useGetSessions(auth, clientId, params);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  function handleUploadClick() {
    setFileDialogOpen(true);
  }

  async function saveAndUploadSession() {
    setProcessing(true);
    try {
      let [response, err] = await createSession(await auth.getAccessToken(), {
        clientId: clientId,
        fileName: sessionFile.name,
        fileType: sessionFile.type,
      });
      if (response) {
        response = await uploadSession(response.uploadUrl, sessionFile);
        if (response) {
          refreshSessions();
          setFileDialogOpen(false);
          setSnackbar('success', 'Your session was successfully uploaded.');
        } else {
          throw {
            error: 'An error occurred while attempting to upload the session data.',
          };
        }
      } else {
        throw {
          error: 'An error occurred while attempting to create the session.',
        };
      }
    } catch (err) {
      setSnackbar('error', err.error);
    } finally {
      setProcessing(false);
    }
  }

  function handleFilter() {
    setLoading(true);
    try {
      setParams({
        searchTerm: searchTerm,
      });
      refreshSessions();
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the sessions.');
    }
    setLoading(false);
  }

  if (clientError) {
    setSnackbar('error', clientError.error);
  }

  useEffect(() => {
    if (sessionsError) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', sessionsError.error);
    }
  }, [sessionsError]);

  const handlePageChange = (event, value) => {
    setParams({
      ...params,
      ...{ startFrom: value - 1 },
    });
    setPage(value);
  };

  const totalPages =
    sessions && sessions.totalSessions
      ? Math.floor((sessions.totalSessions - 1) / sessions.limit) + 1
      : 1;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={'row'}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" component="h3">
          {`Client: ${(client && client.firstName) || ''} ${(client && client.lastName) || ''}`}
        </Typography>
      </Box>

      <FileDialog
        open={fileDialogOpen}
        setOpen={setFileDialogOpen}
        file={sessionFile}
        setFile={setSessionFile}
        handleSave={saveAndUploadSession}
        accept="text/plain"
        processing={processing}
      />
      <Stack direction="row" spacing={2}>
        <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
          Sessions
        </Typography>
        <IconButton size="large" color="primary" onClick={handleUploadClick}>
          <UploadIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          id="filled-search"
          label="Search Sessions"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        <IconButton size="large" color="primary" onClick={handleFilter}>
          <SearchIcon />
        </IconButton>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {sessions &&
          sessions.sessions &&
          sessions.sessions.map((session) => {
            return (
              <Grid xs={12} sm={6} lg={4} key={session.sessionId}>
                <SessionCard
                  clientId={clientId}
                  session={session}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                  sx={{ pt: 2 }}
                />
              </Grid>
            );
          })}
        <br />
        {!sessions && loading && (
          <Grid xs>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
      {totalPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid xs>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
