import { FullLinearGradient } from './common';

const LungGraph = ({ eventGraphRef }) => {
  return (
    <svg
      viewBox="0 25 550 500"
      height="500"
      width="100%"
      ref={eventGraphRef}
      style={{ marginTop: '1rem' }}
    >
      <g transform="translate(-15, 0)">
        <g transform="matrix(0.8316769599914551, 0, 0, 0.9877910614013672, 38.55212783813488, 20.773906707763672)">
          <g>
            <path
              d="M304.616,166.29c-29.72,0-31.176-9.652-31.176-28.156V37.126c0-12.924-10.516-23.44-23.44-23.44 s-23.44,10.516-23.44,23.44v101.008c0,18.504-1.456,28.156-31.184,28.156c-2.16,0-3.976,1.748-3.976,3.908v39.068 c0,2.16,1.832,3.908,3.992,3.908c23.22,0,41.596-5.324,54.596-15.832c13.004,10.508,31.412,15.832,54.628,15.832 c2.16,0,3.984-1.748,3.984-3.908v-39.068C308.6,168.038,306.776,166.29,304.616,166.29z"
              fill="white"
              strokeWidth="6"
              stroke="black"
            />
          </g>
        </g>
        <g transform="matrix(0.8316769599914551, 0, 0, 0.9877910614013672, 30, 1.9199540615081787)">
          <g>
            <path
              d="M205.024,270.586c-2.752-24.696-5.588-50.244-5.588-74.996c0-62.82-6.876-146.332-66.276-146.332 C60.94,49.258,0.004,241.01,0.004,361.414c-0.028,3.8-0.472,93.38,24.06,118.108c4.472,4.504,9.568,6.792,15.156,6.792 c14.764,0,22.104-5.64,32.268-13.436c12.752-9.788,30.208-23.196,74.508-35.968C221.1,415.254,213.296,344.99,205.024,270.586z"
              strokeWidth="6"
              stroke="black"
              fill="url(#mygrad)"
            />
          </g>
        </g>
        <g transform="matrix(0.8316769599914551, 0, 0, 0.9877910614013672, 47, 1.9199540615081787)">
          <g>
            <path
              d="M499.996,361.446c0-120.432-60.932-312.188-133.148-312.188c-59.408,0-66.272,83.512-66.272,146.332 c0,24.752-2.84,50.296-5.584,74.996c-8.272,74.404-16.084,144.668,59.028,166.324c44.292,12.772,61.752,26.18,74.5,35.96 c10.164,7.804,17.504,13.444,32.268,13.444c5.584,0,10.68-2.288,15.152-6.792C500.468,454.794,500.028,365.218,499.996,361.446z"
              strokeWidth="6"
              stroke="black"
              fill="url(#mygrad)"
            />
          </g>
        </g>
      </g>

      <g transform="translate(500, 50)" id="axis_g" strokeWidth="5" />

      <FullLinearGradient />
    </svg>
  );
};

export default LungGraph;
