import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validator from '../../common/validations.mjs';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText';

export default function Login({ email, setEmail, password, setPassword, handleLogin }) {
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validate(event) {
    event.preventDefault();
    let valid = true;
    if (!validator().isEmail(email)) {
      valid = false;
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!validator().isPassword(password)) {
      valid = false;
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (valid) {
      handleLogin();
    }
  }

  return (
    <Box component="form" noValidate onSubmit={validate}>
      <div>
        <TextField
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          required
          error={emailError}
          helperText={emailError && 'This email address is not valid.'}
          fullWidth
          margin="normal"
          label="Email"
          autoFocus
        />
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" required error={passwordError}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            value={password}
            label="Password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <FormHelperText>
            {passwordError && 'This password does not meet the complexity requirements.'}
          </FormHelperText>
        </FormControl>
      </div>
      <Grid container>
        <Grid xs={6}>
          <Typography variant="subtitle1" component="span">
            <Link conmponent={RouterLink} href="/forgotPassword">
              Forgot Password
            </Link>
          </Typography>
        </Grid>
        <Grid xs={6} padding={0}>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button variant="outlined" color="error" LinkComponent={RouterLink} to="/">
              Cancel
            </Button>
            <Button sx={{ ml: 1 }} variant="contained" type="submit">
              Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
