import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toTitleCase } from '../../common/utils.mjs';

export default function sexSelect({ gender, setGender }) {
  const genders = [
    'agender',
    'ambi',
    'bi',
    'cis male',
    'cis female',
    'fluid',
    'intersex',
    'non-binary',
    'pan',
    'poly',
    'trans male',
    'trans female',
    'two-spirit',
  ];

  function handleChange(event) {
    setGender(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="gender-selector-label">Gender</InputLabel>
      <Select
        labelId="gender-selector-label"
        id="gender-selector"
        value={gender === null ? '' : gender}
        label="Gender"
        onChange={handleChange}
      >
        {genders.map((gender) => {
          return (
            <MenuItem key={gender} value={gender}>
              {toTitleCase(gender)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
