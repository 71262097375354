import { useEffect, useState } from 'react';
import { useRequest } from './requestContainer';

export default function useResult(request) {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const requester = useRequest();

  function triggerRefresh() {
    setRefresh(!refresh);
  }

  useEffect(
    function () {
      requester(request)
        .then((response) => {
          setResult(response);
          setError(null);
        })
        .catch((err) => {
          setResult(null);
          setError(err);
        });
    },
    [request, requester, refresh]
  );

  return [result, error, triggerRefresh];
}
