import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toTitleCase } from '../../common/utils.mjs';

export default function sexSelect({ sex, setSex }) {
  const sexes = ['male', 'female'];

  function handleChange(event) {
    setSex(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="sex-selector-label">Sex</InputLabel>
      <Select
        labelId="sex-selector-label"
        id="sex-selector"
        value={sex === null ? '' : sex}
        label="Sex"
        onChange={handleChange}
      >
        {sexes.map((sex) => {
          return (
            <MenuItem key={sex} value={sex}>
              {toTitleCase(sex)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
