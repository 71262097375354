import { FullLinearGradient } from './common';

const MuscleGraphic = ({ eventGraphRef }) => {
  return (
    <div
      style={{
        backgroundImage: 'url("/images/graphs/traps.jpg")',
        backgroundSize: '600px 470px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '38px',
        backgroundPositionY: '-5px',
        marginLeft: '2rem',
      }}
    >
      <svg viewBox="25 25 500 500" height="500" width="800" ref={eventGraphRef}>
        <g transform="translate(-199, 52)">
          <g transform="scale(4.35,4.35)">
            <path
              fill="url(#mygradLeft)"
              stroke="black"
              strokeWidth="1"
              d="M 95 100 L 95 0 L 85 0 c -1.367 6.129 -3 8 -6 12 Q 77 14 75 16 Q 64 22 50 33 C 46 39 41 54 41 60 Q 43 61 49 61 Q 53 56 56 57 Q 60 58 61 65 Q 68 66 76 65 Q 77 72 80 80 Q 82 86 85 90 L 87 100 Z Z Z"
            />
          </g>
        </g>

        <g transform="translate(626, 52)">
          <g transform="scale(-4.35,4.35)">
            <path
              fill="url(#mygradRight)"
              stroke="black"
              strokeWidth="1"
              d="M 95 100 L 95 0 L 85 0 c -1.367 6.129 -3 8 -6 12 Q 77 14 75 16 Q 64 22 50 33 C 46 39 41 54 41 60 Q 43 61 49 61 Q 53 56 56 57 Q 60 58 61 65 Q 68 66 76 65 Q 77 72 80 80 Q 82 86 85 90 L 87 100 Z Z Z"
            />
          </g>
        </g>

        <g transform="translate(500, 50)" id="axis_g" strokeWidth="5" />

        <FullLinearGradient />
      </svg>
      </div>
  );
};

export default MuscleGraphic;
