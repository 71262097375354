import { useMemo } from 'react';
import { getClient } from '../requests.mjs';
import useResult from '../response';

export default function useGetClient(auth, clientId) {
  const request = useMemo(function () {
    return getClient(auth, clientId);
  }, []);
  return useResult(request);
}
