import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.dark',
        color: 'secondary.contrastText',
        p: 10,
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <Typography variant="body1" align="center">
          {'NeuroInfiniti - v'}
          {process.env.REACT_APP_VERSION}
        </Typography>
        <Typography variant="body1" align="center">
          {'Copyright © '}
          {new Date().getFullYear()}
          {' - '}
          <Link color="inherit" href="https://brainbasedhs.com/">
            TruChiro LLC
          </Link>{' '}
        </Typography>
      </Container>
    </Box>
  );
}
