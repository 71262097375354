import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/ReadMore';
import EnabledIcon from '@mui/icons-material/CheckCircle';
import DisabledIcon from '@mui/icons-material/Cancel';
import { toTitleCase } from '../../common/utils.mjs';

export default function clientCard({ client, handleEdit, handleDelete, locale, timeZone, sx }) {
  function handleEditClick() {
    handleEdit(client);
  }

  function handleDeleteClick() {
    handleDelete(client.clientId);
  }

  const createdDate = new Date(client.createdDate);
  const displayDate = createdDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = createdDate.toLocaleTimeString(locale, { timeZone: timeZone });

  return (
    <Card sx={sx}>
      <CardContent>
        <RouterLink to={`/client/${client.clientId}`}>
          <Typography variant="h6" color="primary">
            {`${client.firstName} ${client.lastName}`}
          </Typography>
        </RouterLink>
        <Typography variant="subtitle1">
          <Link href={`mailto:${client.email}`}>{client.email}</Link>
        </Typography>
        <Typography variant="subtitle1">{`Created: ${displayDate} ${displayTime}`}</Typography>
        <Typography variant="subtitle1">{`Sex: ${toTitleCase(client.sex)}`}</Typography>
        <Typography variant="subtitle1">{`Gender: ${toTitleCase(client.gender)}`}</Typography>
        <Typography variant="subtitle1">
          {`Date of Birth: ${client.dateOfBirth ? client.dateOfBirth : ''}`}
        </Typography>
        <Typography variant="subtitle1">
          Data Use Authorized:
          {client.isDataUseAuthorized ? (
            <EnabledIcon color="primary" fontSize="small" />
          ) : (
            <DisabledIcon color="error" fontSize="small" />
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <RouterLink to={`/client/${client.clientId}`}>
          <Tooltip title="View">
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </RouterLink>
        <Tooltip title="Edit">
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
