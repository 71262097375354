import { FullLinearGradient } from './common';

const ThermometerGraph = ({ eventGraphRef }) => {
  return (
    <svg
      viewBox="0 25 550 550"
      height="500"
      width="100%"
      ref={eventGraphRef}
      style={{ marginTop: '1rem' }}
    >
      <g transform="matrix(0.05031200498342514, 0, -0.00011900001118192449, 0.0555730015039444, 312.3127136230469, 3.901550054550171)">
        <g transform="matrix(1, 0, 0, 1, -2000.605412, 469.411896)">
          <path d="M417 1084c0,-596 487,-1084 1084,-1084 596,0 1083,488 1083,1084l0 5440c7,17 417,383 417,1037 0,829 -672,1501 -1500,1501 -829,0 -1501,-672 -1501,-1501 0,-654 410,-1020 417,-1037l0 -5440zm2056 0c0,-535 -438,-973 -972,-973 -535,0 -973,438 -973,973l-16 5501c-127,128 -228,280 -298,449 -213,521 -89,1117 304,1510 543,543 1422,543 1965,0 393,-393 517,-989 304,-1510 -70,-169 -171,-321 -298,-449l-16 -5501z" />
          <path
            stroke="black"
            strokeWidth="113.33px"
            fill="url(#mygrad)"
            d="M 1167 618 c 0 -445 667 -435 667 0 l 0 6314 c 422 224 511 793 171 1133 c -279 279 -730 279 -1008 0 c -341 -340 -252 -909 170 -1133 l 0 -6314 z l 0 1228 z"
          />
        </g>
      </g>
      <g transform="translate(400, 50)" id="axis_g" strokeWidth="5" />

      <FullLinearGradient />
    </svg>
  );
};

export default ThermometerGraph;
