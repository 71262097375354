import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function fileDialog({
  open,
  setOpen,
  file,
  setFile,
  handleSave,
  processing,
  additionalText,
  accept,
}) {
  function handleClose() {
    setFile('');
    setOpen(false);
  }

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select a File</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          {`Please browse to the location of the file you would like to upload. ${
            additionalText || ''
          }`}
        </DialogContentText>
        <Button variant="contained" component="label">
          Choose File
          <input type="file" accept={accept || '*'} hidden onChange={handleChange} />
        </Button>
        <Box sx={{ pt: 2 }}>{file && file.name && <TextField value={file.name} disabled />}</Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={processing || null}
          color="error"
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={processing || null}
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
