import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import validator from '../../common/validations.mjs';
import UserEdit from './edit';

export default function createUser({ user, processing, handleCreate, handleCancel }) {
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState(false);

  function validate(user) {
    let valid = true;
    if (!validator().isEmail(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (valid) {
      handleCreate({
        email: email,
        firstName: user.firstName,
        lastName: user.lastName,
        locale: user.locale,
        timeZone: user.timeZone,
      });
    }
  }

  function resetForm() {
    setEmail(user.email);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={emailError}>
          <InputLabel htmlFor="outlined-email">Email</InputLabel>
          <OutlinedInput
            id="outlined-email"
            type="text"
            value={email}
            label="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <FormHelperText>{emailError && 'This email is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <UserEdit
        user={user}
        processing={processing}
        handleUpdate={validate}
        handleCancel={resetForm}
      />
    </Box>
  );
}
