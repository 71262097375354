export const idealBrainScoreData = [
  {
    featureName: 'Theta ',
    'Eyes Open BL': 18.645,
    'Eyes Closed BL': 21.3,
    'Math Test': 17.814,
    'Recovery 1': 21.46,
    Sounds: 18.2,
    'Recovery 2': 20.7,
    'HRV Task': 17.9,
    'Recovery 3': 21.815,
  },
  {
    featureName: 'Alpha',
    'Eyes Open BL': 15.513,
    'Eyes Closed BL': 27.009,
    'Math Test': 14.592,
    'Recovery 1': 26.945,
    Sounds: 17.489,
    'Recovery 2': 27.706,
    'HRV Task': 18.51,
    'Recovery 3': 28.884,
  },
  {
    featureName: 'SMR',
    'Eyes Open BL': 5.84,
    'Eyes Closed BL': 7.82,
    'Math Test': 5.543,
    'Recovery 1': 7.682,
    Sounds: 5.7,
    'Recovery 2': 7.91,
    'HRV Task': 6.0,
    'Recovery 3': 7.751,
  },
  {
    featureName: 'Beta',
    'Eyes Open BL': 32.316,
    'Eyes Closed BL': 28.494,
    'Math Test': 32.2,
    'Recovery 1': 28.556,
    Sounds: 32.747,
    'Recovery 2': 27.2,
    'HRV Task': 31.334,
    'Recovery 3': 26.8,
  },
];

export const idealHRVData = [
  {
    featureName: 'Very Low ',
    'Eyes Open BL': 5,
    'Eyes Closed BL': 5,
    'Math Test': 4,
    'Recovery 1': 5,
    Sounds: 8,
    'Recovery 2': 7,
    'HRV Task': 6,
    'Recovery 3': 7,
  },
  {
    featureName: 'Low',
    'Eyes Open BL': 90,
    'Eyes Closed BL': 92,
    'Math Test': 90,
    'Recovery 1': 84,
    Sounds: 84,
    'Recovery 2': 86,
    'HRV Task': 90,
    'Recovery 3': 84,
  },
  {
    featureName: 'High',
    'Eyes Open BL': 5,
    'Eyes Closed BL': 5,
    'Math Test': 4,
    'Recovery 1': 5,
    Sounds: 8,
    'Recovery 2': 7,
    'HRV Task': 6,
    'Recovery 3': 7,
  },
];

export const idealHRData = [
  {
    title: 'Eyes Open BL',
    value: 61.47,
  },
  {
    title: 'Eyes Closed BL',
    value: 61.023,
  },
  {
    title: 'Math Test',
    value: 65.3,
  },
  {
    title: 'Recovery 1',
    value: 62.2,
  },
  {
    title: 'Sounds',
    value: 61.47,
  },
  {
    title: 'Recovery 2',
    value: 61.023,
  },
  {
    title: 'HRV Task',
    value: 65.3,
  },
  {
    title: 'Recovery 3',
    value: 62.2,
  },
];

export const idealRRData = [
  {
    title: 'Eyes Open BL',
    value: 8.5,
  },
  {
    title: 'Eyes Closed BL',
    value: 6.3,
  },
  {
    title: 'Math Test',
    value: 10.1,
  },
  {
    title: 'Recovery 1',
    value: 6.8,
  },
  {
    title: 'Sounds',
    value: 9.6,
  },
  {
    title: 'Recovery 2',
    value: 6.6,
  },
  {
    title: 'HRV Task',
    value: 6,
  },
  {
    title: 'Recovery 3',
    value: 6.3,
  },
];

export const idealHandDataFahrenheit = [
  {
    title: 'Eyes Open BL',
    value: 94.2,
  },
  {
    title: 'Eyes Closed BL',
    value: 94.8,
  },
  {
    title: 'Math Test',
    value: 93.8,
  },
  {
    title: 'Recovery 1',
    value: 94.6,
  },
  {
    title: 'Sounds',
    value: 94,
  },
  {
    title: 'Recovery 2',
    value: 94.7,
  },
  {
    title: 'HRV Task',
    value: 94.8,
  },
  {
    title: 'Recovery 3',
    value: 95.1,
  },
];

export const idealHandDataCelsius = [
  {
    title: 'Eyes Open BL',
    value: 34.56,
  },
  {
    title: 'Eyes Closed BL',
    value: 34.89,
  },
  {
    title: 'Math Test',
    value: 34.33,
  },
  {
    title: 'Recovery 1',
    value: 34.78,
  },
  {
    title: 'Sounds',
    value: 34.45,
  },
  {
    title: 'Recovery 2',
    value: 34.83,
  },
  {
    title: 'HRV Task',
    value: 34.89,
  },
  {
    title: 'Recovery 3',
    value: 35.06,
  },
];

export const idealSkinData = [
  {
    title: 'Eyes Open BL',
    value: 1.2,
  },
  {
    title: 'Eyes Closed BL',
    value: 1,
  },
  {
    title: 'Math Test',
    value: 1.45,
  },
  {
    title: 'Recovery 1',
    value: 1.2,
  },
  {
    title: 'Sounds',
    value: 1.3,
  },
  {
    title: 'Recovery 2',
    value: 1.1,
  },
  {
    title: 'HRV Task',
    value: 1.05,
  },
  {
    title: 'Recovery 3',
    value: 0.9,
  },
];

export const idealMuscleLeftData = [
  {
    title: 'Eyes Open BL',
    value: 1.5,
  },
  {
    title: 'Eyes Closed BL',
    value: 1.3,
  },
  {
    title: 'Math Test',
    value: 2,
  },
  {
    title: 'Recovery 1',
    value: 1.6,
  },
  {
    title: 'Sounds',
    value: 1.8,
  },
  {
    title: 'Recovery 2',
    value: 1.4,
  },
  {
    title: 'HRV Task',
    value: 1.35,
  },
  {
    title: 'Recovery 3',
    value: 1.22,
  },
];

export const idealMuscleRightData = [
  {
    title: 'Eyes Open BL',
    value: 1.6,
  },
  {
    title: 'Eyes Closed BL',
    value: 1.3,
  },
  {
    title: 'Math Test',
    value: 2,
  },
  {
    title: 'Recovery 1',
    value: 1.6,
  },
  {
    title: 'Sounds',
    value: 1.8,
  },
  {
    title: 'Recovery 2',
    value: 1.4,
  },
  {
    title: 'HRV Task',
    value: 1.28,
  },
  {
    title: 'Recovery 3',
    value: 1.15,
  },
];
