import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ViewIcon from '@mui/icons-material/ReadMore';

export default function sessionCard({ clientId, session, locale, timeZone, sx }) {
  const sessionDate = new Date(session.createdDate);
  const displayDate = sessionDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = sessionDate.toLocaleTimeString(locale, { timeZone: timeZone });
  return (
    <Card sx={sx}>
      <CardContent>
        <RouterLink to={`/client/${clientId}/session/${session.sessionId}`}>
          <Typography variant="h6" color="primary">{session.name}</Typography>
        </RouterLink>
        <Typography variant="subtitle1">{`Uploaded: ${displayDate} ${displayTime}`}</Typography>
      </CardContent>
      <CardActions>
        <RouterLink to={`/client/${clientId}/session/${session.sessionId}`}>
          <Tooltip title="View">
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </RouterLink>
      </CardActions>
    </Card>
  );
}
