import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validator from '../../common/validations.mjs';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';

export default function verifyResetCode({
  email,
  setEmail,
  code,
  setCode,
  password,
  setPassword,
  handlePasswordReset,
}) {
  const [showPassword, setShowPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validate(event) {
    event.preventDefault();
    let valid = true;
    if (!validator().isEmail(email)) {
      valid = false;
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!validator().isValidatorCode(code)) {
      valid = false;
      setCodeError(true);
    } else {
      setCodeError(false);
    }
    if (!validator().isPassword(password)) {
      valid = false;
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (valid) {
      handlePasswordReset();
    }
  }

  return (
    <Box component="form" noValidate onSubmit={validate}>
      <div>
        <TextField
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          error={emailError}
          helperText={emailError && 'This email address is not valid.'}
          fullWidth
          margin="normal"
          label="Email"
          autoFocus
        />
      </div>
      <div>
        <TextField
          id="verification-code"
          value={code}
          onChange={(event) => {
            setCode(event.target.value);
          }}
          error={codeError}
          helperText={codeError && 'The coed you provided is not in the correct format.'}
          fullWidth
          margin="normal"
          label="Verification Code"
        />
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={passwordError}>
          <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            value={password}
            label="New Password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <FormHelperText>
            {passwordError && 'This password does not meet the complexity rule.'}
          </FormHelperText>
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button variant="outlined" color="error" LinkComponent={RouterLink} to="/">
          Cancel
        </Button>
        <Button sx={{ ml: 1 }} variant="contained" type="submit">
          Set New Password
        </Button>
      </Box>
    </Box>
  );
}
