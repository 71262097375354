import Box from '@mui/material/Box';
import MainMenu from './mainmenu';

export default function SideBar({ isLoggedIn, primaryGroup }) {
  return (
    <Box
      sx={{
        a: {
          minHeight: '4rem',
        },
        'a:hover': {
          backgroundColor: 'primary.main 4%',
        },
        color: 'primary.main',
        svg: {
          color: 'primary.main',
        },
      }}
    >
      <MainMenu isLoggedIn={isLoggedIn} primaryGroup={primaryGroup} />
    </Box>
  );
}
