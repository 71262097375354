import { useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetUsers from '../../apis/truChiro/v1/user/getUsers';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import TextField from "@mui/material/TextField";
import UserCard from '../../components/user/card';
import UserEdit from '../../components/user/edit';
import UserCreate from '../../components/user/create';
import request, {
  updateUser,
  createUser,
  disableUser,
  enableUser,
  resetPassword,
  deleteUser,
} from '../../apis/truChiro/v1/requests.mjs';

export default function Users() {
  const [setSnackbar, loginState, setLoginState, auth] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    locale: 'en-US',
    timeZone: 'America/Toronto',
  });
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({});
  const [users, error, refreshUsers] = useGetUsers(auth, params);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  function handleUserCreate() {
    setUser({
      email: '',
      firstName: '',
      lastName: '',
      locale: 'en-US',
      timeZone: 'America/Toronto',
    });
    setCreateDialogOpen(true);
  }

  function handleUserEdit(user) {
    setUser(user);
    setEditDialogOpen(true);
  }

  function handleDialogClose() {
    setCreateDialogOpen(false);
    setEditDialogOpen(false);
  }

  async function handleCreateUser(user) {
    setProcessing(true);
    try {
      await request(createUser(auth, user));
      setCreateDialogOpen(false);
      refreshUsers();
      setSnackbar(
        'success',
        'The user was created successfully. They have been sent an invitation email.'
      );
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the user.');
    }
    setProcessing(false);
  }

  async function handleUpdateUser(user) {
    setProcessing(true);
    try {
      await request(updateUser(auth, user));
      setEditDialogOpen(false);
      refreshUsers();
      setSnackbar('success', 'The user information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the user information.');
    }
    setProcessing(false);
  }

  async function handleUserDisable(id) {
    setProcessing(true);
    try {
      await request(disableUser(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user was disabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to disable the user.');
    }
    setProcessing(false);
  }

  async function handleUserEnable(id) {
    setProcessing(true);
    try {
      await request(enableUser(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user was enabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to disable the user.');
    }
    setProcessing(false);
  }

  async function handleUserResetPassword(id) {
    setProcessing(true);
    try {
      await request(resetPassword(auth, id));
      refreshUsers();
      setSnackbar(
        'success',
        "The user's password was reset successfully. They have been sent an email."
      );
    } catch (err) {
      console.log(err);
      setSnackbar('error', "An error occurred while attempting to reset the user's password.");
    }
    setProcessing(false);
  }

  async function handleUserDelete(id) {
    setProcessing(true);
    try {
      await request(deleteUser(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user was deleted successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to delete the user.');
    }
    setProcessing(false);
  }

  function handleFilter() {
    setLoading(true);
    try {
      setParams({
        searchTerm: searchTerm,
      });
      refreshUsers();
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the users.');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (error) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', error.error);
    }
  }, [error]);

  const handlePageChange = (event, value) => {
    setParams({
      ...params,
      ...{ startFrom: value - 1 },
    });
    setPage(value);
  };

  const totalPages = users && users.totalUsers
    ? Math.floor((users.totalUsers - 1) / users.limit) + 1
    : 1;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
          Users
        </Typography>
        <IconButton size="large" color="primary" onClick={handleUserCreate}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          id="filled-search"
          label="Search Users"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />
        <IconButton
          size="large"
          color="primary"
          onClick={handleFilter}
        >
          <SearchIcon />
        </IconButton>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {users &&
          users.users &&
          users.users.map((user) => {
            return (
              <Grid xs={12} sm={4} lg={3} key={user.therapistId}>
                <UserCard
                  user={user}
                  processing={processing}
                  handleEdit={handleUserEdit}
                  handleDisable={handleUserDisable}
                  handleEnable={handleUserEnable}
                  handleResetPassword={handleUserResetPassword}
                  handleDelete={handleUserDelete}
                  sx={{ mb: 2 }}
                />
              </Grid>
            );
          })}
        {!users && loading && <CircularProgress color="primary" />}
      </Grid>
      {totalPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid xs>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={editDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>User Information</DialogTitle>
        <DialogContent>
          <UserEdit
            user={user}
            processing={processing}
            handleUpdate={handleUpdateUser}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={createDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <UserCreate
            user={user}
            processing={processing}
            handleCreate={handleCreateUser}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
